<template>
  <div>
    <!-- main content -->
    <div class="site-container">
      <div v-if="role == 'Admin'">
        <text-editor ref="editor" :text="item.content" />
        <b-button class="mt-2" variant="success" @click="save">Salvar</b-button>
      </div>
      <div v-else v-html="item.content" />
    </div>

    <div class="site-container mt-2">
      <newsletter-card />
    </div>

    <!-- footer -->
    <Footer />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

import TextEditor from '@/views/TextEditor.vue'

import RichText from '@/services/RichText'
import toast from '@/mixins/toast'
import Footer from '../components/parts/footer/Footer.vue'
import NewsletterCard from '../components/NewsletterCard.vue'

export default {

  components: {
    NewsletterCard,
    TextEditor,
    BButton,
    Footer,
  },
  mixins: [toast],

  data() {
    return {
      role: '',
      item: { name: 'TERMS_USE', content: '' },
    }
  },

  async mounted() {
    this.role = await localStorage.getItem('role')
    const { result } = (await RichText.getByName(this.item.name)).data
    if (result.items && result.items.length > 0) this.item = result.items[0].richText
  },

  methods: {
    async save() {
      try {
        this.item.content = this.$refs.editor.content
        await RichText.setItem(this.item)
        this.msgSuccess('')
      } catch (e) {
        this.msgError(e.message)
      }
    },
  },
}
</script>
